<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getEmployees"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <a v-if="user.business.is_tap_engine" class="btn btn-info btn-sm mr-2" :href="leaderBoardReportLink" target="_blank">View Leaderboard</a>
              <button class="btn btn-dark-blue btn-sm mr-4" @click="showAddEmployee">Add Employee</button>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getEmployees"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingEmployees">
              <div class="table-responsive mb-0">
                <b-table
                    :items="employees"
                    :fields="dbColumns"
                    :sort-by.sync="datatable.queries.sort_by"
                    :sort-desc.sync="datatable.queries.sort_desc"
                    :per-page="datatable.queries.per_page"
                    data-export-types="['csv']"
                    :no-local-sorting="true"
                    @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(slug)="data">
                    <div v-if="data.item.card_id">
                      {{tapUrl + 'c/' + data.item.card_id}}
                      <button class="btn btn-sm btn-white" @click="copyLink(tapUrl + 'c/' + data.item.card_id)"><i class="uil uil-copy"></i> copy</button>
                    </div>
                    <div v-else>
                      {{ tapUrl }}r/{{user.business.unique_id}}/{{data.item.slug}}
                      <button class="btn btn-sm btn-white" @click="copyLink(tapUrl + 'r/' + user.business.unique_id + '/' + data.item.slug)"><i class="uil uil-copy"></i> copy</button>
                    </div>
                  </template>
                  <template v-slot:cell(positive_feedback_count)="data">
                    <div class="text-success font-weight-bold">
                      {{data.item.positive_feedback_count}}
                    </div>
                  </template>
                  <template v-slot:cell(negative_feedback_count)="data">
                    <div class="text-danger font-weight-bold">
                      {{data.item.negative_feedback_count}}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <button class="btn btn-sm btn-primary" @click="editEmployee(data.item)"><i class="uil uil-edit" /></button>
                    <button class="btn btn-sm btn-danger ml-2" @click="deleteEmployee(data.item)"><i class="uil uil-trash-alt" /></button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalEmployees }} employees
                  </div>
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="totalEmployees"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showAddForm" title="Add Employee" hide-footer>
      <ul v-if="Object.keys(errors).length" class="text-danger pl-3 mb-5">
        <template v-for="(errorArray) in errors">
          <li v-for="(error,key) in errorArray" :key="key">{{error}}</li>
        </template>
      </ul>
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="employeeForm"
      >
        <form
            class="authentication-form"
            @submit.prevent="handleSubmit(addEmployee)"
        >
        <div v-if="user.business.is_tap_engine" class="row m-0 employee-card">
          <div class="col-sm-7 pl-0">
            <label class="form-label mt-3">
              Card ID
            </label>
            <input v-model="employee.card_id" type="text" class="form-control" placeholder="Card ID Number">
            <small>Leave blank if no id number on card</small>
          </div>
          <div class="col-sm-5 text-center">
            <img src="/images/TEBack-small.png" alt="Card" class="mx-auto" style="max-width: 100%; height: auto" />
          </div>
        </div>
          <label class="form-label" :class="{ 'mt-3': user.business.is_tap_engine }">Name</label>
          <input v-model="employee.name" type="text" class="form-control" placeholder="Employee Name">
          <div v-if="user.business.is_tap_engine">
            <label class="form-label mt-4">Cell Phone <small>(optional) We will send the leaderboard report to this number</small></label>
            <input v-model="employee.phone" type="text" class="form-control" placeholder="Employee Cell Phone">
            <div class="mt-2">
              <input v-model="employee.optIn" :checked="employee.phone !== ''" class="form-check-inline mr-1"
                     type="checkbox"/> <small>I agree to receive text message alerts at this phone number. You can opt out by replying STOP.</small>
            </div>
            <label class="form-label mt-4">Email <small>(optional) We will send the leaderboard report to this email</small></label>
            <input v-model="employee.email" type="text" class="form-control" placeholder="Employee Email">
          </div>

        <b-form-group class="mt-3">
          <b-button variant="light" @click="showAddForm = false"
          >Cancel</b-button>
          <b-button
              variant="primary"
              :disabled="loadingCreate || (invalid && touched)"
              type="submit"
              class="float-right"
          >
            <b-spinner v-if="loadingCreate" small />
            <span v-else>Create</span>
          </b-button>
        </b-form-group>
      </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showEditForm" title="Edit Employee" hide-footer>
      <ul v-if="Object.keys(errors).length" class="text-danger pl-3 mb-5">
        <template v-for="(errorArray) in errors">
          <li v-for="(error,key) in errorArray" :key="key">{{error}}</li>
        </template>
      </ul>
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="employeeForm"
      >
        <form
            class="authentication-form"
            @submit.prevent="handleSubmit(editEmployeeSubmit)"
        >
          <div v-if="user.business.is_tap_engine" class="row m-0 employee-card">
            <div class="col-sm-7 pl-0">
              <label class="form-label mt-3">
                Card ID
              </label>
              <input v-model="editingEmployee.card_id" type="text" class="form-control" placeholder="Card ID Number">
              <small>Leave blank if no id number on card</small>
            </div>
            <div class="col-sm-5 text-center">
              <img src="/images/TEBack-small.png" alt="Card" class="mx-auto" style="max-width: 100%; height: auto" />
            </div>
          </div>
          <label class="form-label mt-3" :class="{ 'mt-3': user.business.is_tap_engine }">Name</label>
          <input v-model="editingEmployee.name" type="text" class="form-control" placeholder="Employee Name">
          <div v-if="user.business.is_tap_engine">
            <label class="form-label mt-4">Cell Phone <small>(optional) We will send the leaderboard report to this number</small></label>
            <input v-model="editingEmployee.phone" type="text" class="form-control" placeholder="Employee Cell Phone">
            <div class="mt-2">
              <input v-model="editingEmployee.optIn" type="checkbox" class="form-check-inline mr-1"/> <small>I agree to receive text message alerts at this phone number. You can opt out by replying STOP.</small>
            </div>
            <label class="form-label mt-4">Email <small>(optional) We will send the leaderboard report to this email</small></label>
            <input v-model="editingEmployee.email" type="text" class="form-control" placeholder="Employee Email">
          </div>
          <b-form-group class="mt-4">
            <b-button variant="light" @click="showEditForm = false"
            >Cancel</b-button>
            <b-button
                variant="primary"
                :disabled="loadingUpdate || (invalid && touched)"
                type="submit"
                class="float-right"
            >
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Employee">
      <p v-if="deletingEmployee"
      >Are you sure you want to delete employee "{{ deletingEmployee.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >

        <b-button variant="danger" @click="handleRemove">
          <b-spinner v-if="loadingRemove" small />
          <span v-else>Delete</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components:{

  },
  data(){
    return {
      errors:[],
      loadingCreate:false,
      loadingUpdate:false,
      loadingEmployees:false,
      loadingRemove:false,
      showAddForm:false,
      showEditForm:false,
      showDeleteModal:false,
      employee:{
        name:'',
        phone:'',
        email:'',
        slug:null,
        business_id:null,
        optIn:true,
        card_id:null
      },
      editingEmployee:null,
      deletingEmployee:null,
      user:null,
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'phone', label: 'Phone Number', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'slug', label: 'Employee URL', sortable: true },
          { key: 'positive_feedback_count', label: 'Thumbs Up', sortable: true },
          { key: 'negative_feedback_count', label: 'Thumbs Down', sortable: true },
          { key: 'actions', label: 'Actions', class:'actions' }
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      tapUrl:'https://app.showmyrep.io/'
    }
  },
  computed:{
    employees() {
      return this.$store.getters['employees/all'] || []
    },
    dbColumns(){
      if(this.user.business.is_tap_engine){
        return [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'phone', label: 'Phone Number', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'slug', label: 'Employee URL', sortable: true },
          { key: 'positive_feedback_count', label: 'Thumbs Up', sortable: true },
          { key: 'negative_feedback_count', label: 'Thumbs Down', sortable: true },
          { key: 'actions', label: 'Actions', class:'actions' }
        ]
      }else{
        return [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'phone', label: 'Phone Number', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'positive_feedback_count', label: 'Thumbs Up', sortable: true },
          { key: 'negative_feedback_count', label: 'Thumbs Down', sortable: true },
          { key: 'actions', label: 'Actions', class:'actions' }
        ]
      }
    },
    totalEmployees() {
      return this.$store.getters['employees/total']
    },
    leaderBoardReportLink(){
      if(!this.user) return;
      let leaderBoardStart = 'https://app.showmyrep.io/';
      if(window.location.origin.includes('staging.')){
        leaderBoardStart = 'https://a618-103-196-161-200.ngrok-free.app/';
      }
      return leaderBoardStart + 'leaderboard/'+this.user.business.unique_id;
    }
  },
  mounted() {
    this.getEmployees();
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    if(window.location.origin.includes('staging.')){
      this.tapUrl = 'https://a618-103-196-161-200.ngrok-free.app/';
    }
  },
  methods:{
    showAddEmployee(){
      const employeeLimit = this.user.business?.limits?.tap_engine_employee_limit ?? 200;
      if(this.employees.length >= employeeLimit){
        Vue.$toast.open({
          message: "Sorry, you have reached your employee limit.",
          type: 'error',
          duration: 5000,
        })
      }else{
        this.showAddForm = true
      }
    },
    resetErrors(){
      this.errors = [];
    },
    copyLink(employeeURL) {
      navigator.clipboard.writeText(employeeURL);
      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getEmployees();
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getEmployees()
    },
    deleteEmployee(employee){
      this.deletingEmployee = employee;
      this.showDeleteModal = true;
    },
    getEmployees(){
      this.loadingEmployees = true

      this.$store
          .dispatch('employees/getAll', {
            queries: this.datatable.queries,
            page: 'index',
          })
          .then((res) => {
            this.loadingEmployees = false
          })
          .catch(() => {
            this.loadingEmployees = false
          })
    },
    editEmployee(employee){
      this.resetErrors();
      this.editingEmployee = {...employee};
      this.showEditForm = true;
    },
    editEmployeeSubmit(){
      this.loadingUpdate = true;
      this.$store
          .dispatch('employees/update',this.editingEmployee)
          .then(() => {
            this.loadingUpdate = false
            this.showEditForm = false;
            this.getEmployees();
            // Reset form
            this.editingEmployee = null;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.errors = err.response.data.errors;
              this.$refs.employeeForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    addEmployee(){
      this.loadingCreate = true
      this.employee.business_id = this.user.business.id;
      this.resetErrors();
      this.$store
          .dispatch('employees/create', this.employee)
          .then(() => {
            this.loadingCreate = false
            this.getEmployees();
            this.showAddForm = false;
            // Reset form
            this.employee.name = '';
            this.employee.slug = null;
            this.employee.phone = '';
            this.employee.email = '';
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.errors = err.response.data.errors;
              this.$refs.employeeForm.setErrors(err.response.data.errors)
            }
            this.loadingCreate = false
          })
    },
    handleRemove() {
      this.loadingRemove = true;
      this.$store
          .dispatch('employees/delete', this.deletingEmployee.id)
          .then(() => {
            this.showDeleteModal = false
            this.loadingRemove = null
            this.getEmployees();
          })
          .catch(() => {
            this.loadingRemove = null
          })
    },
  }
}
</script>
<style>
  #card-help-tooltip .tooltip-inner{
    max-width:240px;
  }
  #card-help-tooltip2 .tooltip-inner{
    max-width:240px;
  }
  .employee-card{
    margin-top: -16px !important;
  }
</style>
